import convert from 'color-convert';

import rotateArray from './util/rotateArray';
import randomInteger from './util/randomInteger';
import clamp from './util/clamp';

let elements = document.querySelectorAll('[data-bind]');
let dataBindings = {};

elements.forEach((element) => {
  var bindingProperty = element.getAttribute('data-bind');

  addToScope();

  // if (element.type === 'text') {
  //   element.onkeyup = () => {
  //     dataBindings[bindingProperty] = element.value;
  //   };
  // }
  // if (bindingProperty === 'rule') {
  //   element.onkeyup = (e) => {
  //     if (e.key === 'Enter')
  //       dataBindings[bindingProperty] = element.value % 256;
  //   };
  // }

  function addToScope() {
    if (!dataBindings.hasOwnProperty(bindingProperty)) {
      let value;
      Object.defineProperty(dataBindings, bindingProperty, {
        configurable: true,
        enumerable: true,
        set: function (newValue) {
          value = newValue;
          elements.forEach((event) => {
            if (event.getAttribute('data-bind') === bindingProperty) {
              if (event.type === 'text') {
                event.value = newValue;
              } else if (event.type === 'number') {
                event.value = newValue;
              } else if (event.type === 'checkbox') {
                event.checked = newValue;
              } else if (!event.type) {
                event.innerHTML = newValue;
              }
            }
          });
        },
        get: function () {
          return value;
        },
      });
    }
  }
});

let clientWidth;
let clientHeight;
let cellSize;
let cellHeight;
let startGridDraw;

const scrollBarThumb = document.getElementById('scrollbar-thumb');
const scrollBar = document.getElementById('scrollbar');

const canvas = document.getElementById('canvas');
const hiddenCanvas = document.getElementById('hidden-canvas');

const context = canvas.getContext('2d');
const hiddenContext = hiddenCanvas.getContext('2d');

const emInPx = parseFloat(
  getComputedStyle(document.getElementById('canvas')).fontSize
);

let shouldShowOverlay = true;

let isChangingRule = false;
let isChangingColor = false;

let aliveHue = randomInteger(1, 360);
let aliveSaturation = 54;
let aliveLightness = 40;

let deadHue = (aliveHue + 290) % 360;
let deadSaturation = 80;
let deadLightness = 70;

let [aliveRed, aliveGreen, aliveBlue] = convert.hsl.rgb(
  aliveHue,
  aliveSaturation,
  aliveLightness
);

let [deadRed, deadGreen, deadBlue] = convert.hsl.rgb(
  deadHue,
  deadSaturation,
  deadLightness
);

let hueDrift = 1;
let saturationDrift = 1;
let lightnessDrift = 1;

let delay = 0;

let iterations = 0;
let lastColorChange = 0;

let grid;

// handle changing rule manually
document
  .querySelector('[data-bind="rule"]')
  .addEventListener('keyup', (event) => {
    if (event.key === 'Enter') {
      const val = event.target.value;
      if (!isNaN(val) && Number(val) === parseInt(val)) {
        dataBindings.rule = parseInt(val) % 255;
      } else {
        event.target.value = dataBindings.rule;
      }
    }
  });

const initializeCanvasAndGrid = () => {
  clientWidth = canvas.clientWidth;
  clientHeight = canvas.clientHeight;
  cellSize = clientWidth / dataBindings.cellWidth;
  cellHeight = Math.round(clientHeight / cellSize);
  startGridDraw = cellHeight;

  canvas.width = clientWidth;
  canvas.height = clientHeight;

  hiddenCanvas.width = dataBindings.cellWidth;
  hiddenCanvas.height = cellHeight;

  context.imageSmoothingEnabled = false;
  context.setTransform(1, 0, 0, 1, 0, 0);
  context.scale(cellSize, cellSize);

  grid = [];

  for (let y = 0; y < cellHeight; y++) {
    grid.push([]);
  }

  for (let x = 0; x < dataBindings.cellWidth; x++) {
    grid[grid.length - 1].push(Math.random() > 0.5 ? true : false);
  }
};

// handle changing cell width
document
  .querySelector('[data-bind="cellWidth"]')
  .addEventListener('keyup', (event) => {
    if (event.key === 'Enter') {
      const val = event.target.value;
      if (!isNaN(val) && Number(val) === parseInt(val)) {
        dataBindings.cellWidth = clamp(parseInt(val), 1, clientWidth);
        initializeCanvasAndGrid();
        run();
      } else {
        event.target.value = dataBindings.cellWidth;
      }
    }
  });

// handle changing rule autoplay
document
  .querySelector('[data-bind="shouldAutoplayRule"]')
  .addEventListener('change', (event) => {
    dataBindings.shouldAutoplayRule = event.target.checked;
  });

// handle changing color autoplay
document
  .querySelector('[data-bind="shouldAutoplayColors"]')
  .addEventListener('change', (event) => {
    dataBindings.shouldAutoplayColors = event.target.checked;
  });

// handle changing shuffle autoplay
document
  .querySelector('[data-bind="shouldAutoplayShuffle"]')
  .addEventListener('change', (event) => {
    dataBindings.shouldAutoplayShuffle = event.target.checked;
  });

const toggleOverlayButton = document.getElementById('toggle-overlay');
const helpContent = document.getElementById('help-content');
const title = document.getElementById('title');

// set visibility of overlay
const setOverlayVisibility = () => {
  toggleOverlayButton.innerHTML = shouldShowOverlay ? '−' : '+';
  helpContent.style.display = shouldShowOverlay ? 'block' : 'none';
  title.style.display = shouldShowOverlay ? 'inline' : 'none';
  scrollBar.style.display = shouldShowOverlay ? 'block' : 'none';
  scrollBarThumb.style.display = shouldShowOverlay ? 'block' : 'none';
};

toggleOverlayButton.addEventListener('click', () => {
  shouldShowOverlay = !shouldShowOverlay;
  setOverlayVisibility();
});

dataBindings.shouldRun = true;
dataBindings.shouldAutoplayRule = true;
dataBindings.shouldAutoplayColors = true;
dataBindings.shouldAutoplayShuffle = true;
dataBindings.rule = randomInteger(1, 256);

document.addEventListener('keydown', function (event) {
  if (event.key === ' ') {
    dataBindings.shouldRun = !dataBindings.shouldRun;
    if (dataBindings.shouldRun) {
      run();
    }
  } else if (event.key === 'h') {
    shouldShowOverlay = !shouldShowOverlay;
    setOverlayVisibility();
  } else if (event.key === 'x') {
    isChangingRule = true;
  } else if (event.key === 'c') {
    isChangingColor = true;
  } else if (event.key === 'ArrowDown') {
    if (!dataBindings.shouldRun) {
      run();
    }
  } else if (event.key === 'ArrowLeft') {
    delay = delay > 0 ? delay - 1 : 0;
  } else if (event.key === 'ArrowRight') {
    delay = delay < 100 ? delay + 1 : 100;
  }
});

document.addEventListener('keyup', function (event) {
  if (event.key === 'x') {
    isChangingRule = false;
  } else if (event.key === 'c') {
    isChangingColor = false;
  }
});

// handle dragging (scrolling) canvas
canvas.addEventListener('mousemove', (event) => {
  if (event.buttons > 0) {
    if (
      startGridDraw + event.movementY < grid.length - cellHeight &&
      startGridDraw + event.movementY >= cellHeight
    ) {
      startGridDraw += event.movementY;
    }
  }
});

document.addEventListener('mousemove', (event) => {
  if (isChangingRule) {
    dataBindings.rule = Math.round((event.x / clientWidth) * 256);
  }

  if (isChangingColor) {
    aliveHue = Math.trunc(aliveHue - event.movementX / 5) % 360;
    deadHue = Math.trunc(deadHue + event.movementX / 5) % 360;

    aliveSaturation = clamp(
      Math.trunc(aliveSaturation - event.movementY / 5),
      25,
      100
    );

    deadSaturation = clamp(
      Math.trunc(deadSaturation + event.movementY / 5),
      25,
      100
    );

    [aliveRed, aliveGreen, aliveBlue] = convert.hsl.rgb(
      aliveHue,
      aliveSaturation,
      aliveLightness
    );

    [deadRed, deadGreen, deadBlue] = convert.hsl.rgb(
      deadHue,
      deadSaturation,
      deadLightness
    );
  }

  if (!dataBindings.shouldRun) draw();
});

const draw = () => {
  let imageData = hiddenContext.getImageData(
    0,
    0,
    dataBindings.cellWidth,
    cellHeight
  );
  let gridToDraw = grid.slice(grid.length - startGridDraw);
  let pixels = imageData.data;
  let r, g, b, offset;

  for (let y = cellHeight - 1; y >= 0; y--) {
    for (let x = 0; x < dataBindings.cellWidth; x++) {
      if (gridToDraw[y][x]) {
        r = aliveRed;
        g = aliveGreen;
        b = aliveBlue;
      } else {
        r = deadRed;
        g = deadGreen;
        b = deadBlue;
      }

      offset = (y * dataBindings.cellWidth + x) * 4;
      pixels[offset] = r;
      pixels[offset + 1] = g;
      pixels[offset + 2] = b;
      pixels[offset + 3] = 255;
    }
  }

  if (cellSize === 1) {
    context.putImageData(imageData, 0, 0);
  } else {
    hiddenContext.putImageData(imageData, 0, 0);

    const imageObject = new Image();

    imageObject.onload = function () {
      context.drawImage(imageObject, 0, 0);
    };

    imageObject.src = hiddenCanvas.toDataURL();
  }

  if (shouldShowOverlay) {
    let fromTopInPx =
      Math.abs(
        1 - (startGridDraw - cellHeight) / (grid.length - cellHeight * 2 - 1)
      ) *
      (clientHeight - 3 * emInPx);

    scrollBarThumb.style.top = `calc(${fromTopInPx + emInPx}px)`;
  }
};

const tick = () => {
  const currentRow = grid[grid.length - 1];
  const w = currentRow.length;
  let nextRow = [];

  for (let i = 0; i < currentRow.length; i++) {
    let n = 0;

    if (i === 0) {
      if (currentRow[0]) n |= 2;
      if (currentRow[1]) n |= 1;
    } else if (i === w - 1) {
      if (currentRow[w - 2] === true) n |= 4;
      if (currentRow[w - 1] === true) n |= 2;
    } else {
      if (currentRow[i - 1] === true) n |= 4;
      if (currentRow[i] === true) n |= 2;
      if (currentRow[i + 1] === true) n |= 1;
    }

    nextRow[i] = (dataBindings.rule % 256 & Math.pow(2, n)) > 0;
  }

  grid.push(nextRow);
  iterations++;
};

const shuffle = () => {
  if (randomInteger(1, 100) > 80) {
    rotateArray(grid[grid.length - 1], randomInteger(-5, 5));
  }

  if (randomInteger(1, 100) > 98) {
    for (let i = 0; i < dataBindings.cellWidth; i++) {
      if (randomInteger(1, 100) > 80) {
        grid[grid.length - 1][i] = !grid[grid.length - 1][i];
      }
    }
  }
};

const randomizeRule = () => {
  if (Math.random() > Math.sin(((iterations % 255) / 255) * Math.PI)) {
    dataBindings.rule = (dataBindings.rule + randomInteger(0, 20)) % 255;
  }
};

const shiftColors = () => {
  saturationDrift = Math.sin(iterations / 100) * 6;
  lightnessDrift = Math.cos(iterations / 100) * 3;

  aliveHue = convert.rgb.hsl(aliveRed, aliveGreen, aliveBlue)[0];

  deadHue = convert.rgb.hsl(deadRed, deadGreen, deadBlue)[0];

  [aliveRed, aliveGreen, aliveBlue] = convert.hsl.rgb(
    (aliveHue + hueDrift) % 360,
    clamp(aliveSaturation + saturationDrift, 1, 100),
    clamp(aliveLightness + lightnessDrift, 1, 100)
  );

  [deadRed, deadGreen, deadBlue] = convert.hsl.rgb(
    (deadHue + hueDrift) % 360,
    clamp(deadSaturation + saturationDrift, 1, 100),
    clamp(deadLightness + lightnessDrift, 1, 100)
  );
};

function run() {
  tick();
  draw();
  if (dataBindings.shouldAutoplayRule) {
    randomizeRule();
  }

  if (dataBindings.shouldAutoplayShuffle) {
    shuffle();
  }

  if (dataBindings.shouldAutoplayColors) {
    if (lastColorChange > 3) {
      shiftColors();
      lastColorChange = 0;
    } else {
      lastColorChange++;
    }
  }

  if (dataBindings.shouldRun) {
    setTimeout(run, delay);
  }
}

dataBindings.cellWidth = 300;

initializeCanvasAndGrid();
run();
