const clamp = (value, min, max) => {
  let clamped = value;
  if (value > max) {
    clamped = max;
  } else if (value < min) {
    clamped = min;
  }

  return clamped;
};

export default clamp;
